<template>
  <div>
    <template v-if="pageLoader">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </template>
    <template v-else>
      <form-render :fields="fields" :listOfFields="listOfFields" :documentsList="listOfDocuments" ref="formReferences" :checklistId="checklistId"></form-render>
    </template>
  </div>
</template>
<script>
import { checklistApi } from '@/plugins/axios_settings'
import { mapGetters } from 'vuex'
import checklist from '@/mixins/checklist'
export default {
  mixins: [checklist],
  props: {
    checklistId: {
      type: String,
      default: () => ''
    },
    formId: {
      type: String,
      default: () => ''
    },
    token: {
      default: () => '',
      type: String
    }
  },
  mounted () {
    this.getFormPropertiesHandler()
    this.$root.$on('saveCheckListHandler', (type) => {
      this.saveUpdateChecklistHandler(true)
    })
    setTimeout(() => {
      this.pageLoader = false
    }, 1000)
  },
  created () {
    setTimeout(() => {
      this.fields = { ...this.fields }
    }, 1000)
  },
  data () {
    return {
      fields: {},
      listOfFields: [],
      listOfDocuments: {},
      pageLoader: true
    }
  },
  computed: {
    ...mapGetters(['userDetails'])
  },
  components: {
    'form-render': () => import('./ChecklistFormRender.vue')
  },
  methods: {
    getFormPropertiesHandler () {
      checklistApi.get(`/formproperties/get_by_form/${this.formId}`, this.token).then((result) => {
        if (this.checklistId) {
          this.getFieldsData()
        }
        if (result && result.data) this.assignDefaultValues(result.data)
      })
    },
    // Default values assigning to the fields
    assignDefaultValues (listOfProperties) {
      const getFormProperties = this.$formatter.cloneVariable(listOfProperties)
      for (let i = 0; i < getFormProperties.length; i++) {
        getFormProperties[i].default_value = getFormProperties[i].default_value ? JSON.parse(getFormProperties[i].default_value) : null
        switch (getFormProperties[i].type) {
          case 1:
          case 2:
          case 4:
          case 8:
            this.fields[getFormProperties[i].name] = getFormProperties[i].default_value.default_value
            break
          case 6:
            if (getFormProperties[i].default_value.default_value) {
              getFormProperties[i].date_value = this.$formatter.formatDate(getFormProperties[i].default_value.default_value, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD')
              this.fields[getFormProperties[i].name] = this.$formatter.formatDate(getFormProperties[i].default_value.default_value, 'YYYY-MM-DDTHH:mm:ss', this.userDetails.dateformat)
            } else if (getFormProperties[i].default_value.default_Value_keyword) {
              getFormProperties[i].date_value = this.$formatter.formatDate(this.$formatter.getCurrentDate(this.userDetails.dateformat), this.userDetails.dateformat, 'YYYY-MM-DD')
              this.fields[getFormProperties[i].name] = this.$formatter.getCurrentDate(this.userDetails.dateformat)
            } else {
              this.fields[getFormProperties[i].name] = ''
            }
            getFormProperties[i].date_menu = false
            break
          case 7:
            if (getFormProperties[i].default_value.default_value) {
              this.fields[getFormProperties[i].name] = getFormProperties[i].default_value.default_value
            } else if (getFormProperties[i].default_value.default_Value_keyword) {
              const currentDateTime = this.$formatter.getCurrentDateTimeUTC()
              const time = this.$formatter.formatDate(currentDateTime, 'DD.MM.YYYYTHH:mm:ss', 'HH:mm')
              this.fields[getFormProperties[i].name] = time
            } else {
              this.fields[getFormProperties[i].name] = ''
            }
            getFormProperties[i].time_menu = false
            break
          case 5:
          {
            let list
            if (getFormProperties[i].default_value.is_multiselect && getFormProperties[i].default_value.default_value) {
              list = []
              list.push(getFormProperties[i].default_value.default_value)
            } else {
              list = getFormProperties[i].default_value.default_value
            }
            this.fields[getFormProperties[i].name] = list
            break
          }
          case 9: this.fields[getFormProperties[i].name] = []
            break
          case 18:
          {
            const defaultValue = getFormProperties[i].default_value
            getFormProperties[i].showSum = this.checkAndGetSumRowData(defaultValue.grid_data.labels)
            if (defaultValue.grid_data && !Object.keys(defaultValue.grid_data.data).length) {
              getFormProperties[i].default_value.grid_data.data = this.addGridFirstRow(+getFormProperties[i].label, defaultValue.grid_data.data)
            }
            break
          }
          case 15:
            this.fields[getFormProperties[i].name] = getFormProperties[i].default_value.grid_data
            break
          case 10:
            this.fields[getFormProperties[i].name] = { answer: '', comments: '', attachments: [] }
            getFormProperties[i].expandsurveysection = false
            break
          case 19:
            this.$set(this.fields, getFormProperties[i].name, { answer: '', comments: '', attachments: [], signature: '', date: '', date_menu: false, date_value: '' })
            getFormProperties[i].expandsurveysection = false
            break
        }
        // separete panel fields and other fields
        const getPanelFieldIndex = getFormProperties.findIndex(x => x._id === getFormProperties[i].default_value.panelId)
        if (getPanelFieldIndex >= 0) {
          getFormProperties[i].isPanelField = true
          if (!getFormProperties[getPanelFieldIndex].panel_fields) getFormProperties[getPanelFieldIndex].panel_fields = []
          getFormProperties[getPanelFieldIndex].panel_fields.push(Object.assign({ ...getFormProperties[i] }, { isPanelField: false }))
        }
      }
      this.listOfFields = getFormProperties
    },
    // to load all the fields data to the checklist
    getFieldsData () {
      checklistApi.get(`/checklists/${this.checklistId}`, this.token).then((result) => {
        if (result && result.data) {
          this.getDocumentsHandler()
          this.modifyListOfFields(result.data.data)
        }
      })
    },
    // get all documents added to the checklist
    getDocumentsHandler (id) {
      const checklistId = id || this.checklistId
      checklistApi.get(`/documents/get_checklist_documents/${checklistId}`).then((response) => {
        if (response && response.data) {
          const result = response.data
          if (result.length) {
            result.forEach(element => {
              element.imgObj = this.mimeTypeOfAttachment(element)
            })
          }
          this.listOfDocuments = result ? this.$formatter.groupBy(result, 'property_name') : {}
        }
      })
    },
    checkAndGetSumRowData (labels) {
      const obj = { showSumRow: false, sumColIndexes: [] }
      labels.forEach((label, index) => {
        const { field } = label
        if (field && field.typedata && field.typedata.isshowsum) {
          obj.showSumRow = true
          obj.sumColIndexes.push(index)
        }
      })
      return obj
    },
    // Adding new row to grid type 18 ,12
    addGridFirstRow (size, dataRowValues) {
      const getGrid = dataRowValues
      const gridData = []
      for (let i = 0; i < size; i++) {
        gridData.push({ value: '' })
      }
      return Object.assign({ ...getGrid }, { [`row_${0}`]: [...gridData] })
    },
    // constructing values for type 5, 15,12, 18
    constructValuesForParticularFields () {
      for (let i = 0; i < this.listOfFields.length; i++) {
        switch (this.listOfFields[i].type) {
          case 15:
          {
            const inventObj = {}
            inventObj.grid_header = this.listOfFields[i].default_value.grid_header
            inventObj.grid_data = this.listOfFields[i].default_value.grid_data
            this.fields[this.listOfFields[i].name] = inventObj
            break
          }
          case 12:
          case 18:
            this.fields[this.listOfFields[i].name] = this.listOfFields[i].default_value.grid_data.data
            break
          case 13:
            if ((this.$refs.formReferences.$refs[`signaturePad_${this.listOfFields[i]._id}`] && this.$refs.formReferences.$refs[`signaturePad_${this.listOfFields[i]._id}`].length)) {
              const signatureData = this.$refs.formReferences.$refs[`signaturePad_${this.listOfFields[i]._id}`][0].saveSignature()
              let data = null
              data = signatureData.data
              this.fields[this.listOfFields[i].name] = data
            }
            if (this.$refs.formReferences.$refs.panelFormReference) {
              this.$refs.formReferences.$refs.panelFormReference.forEach((x) => {
                if ((x.$refs[`signaturePad_${this.listOfFields[i]._id}`] && x.$refs[`signaturePad_${this.listOfFields[i]._id}`].length)) {
                  const signatureData = x.$refs[`signaturePad_${this.listOfFields[i]._id}`][0].saveSignature()
                  let data = null
                  data = signatureData.data
                  this.fields[this.listOfFields[i].name] = data
                }
              })
            }
            break
        }
      }
    },
    // when updating(Edit part) loading all data
    modifyListOfFields (allFields) {
      const fields = JSON.parse(allFields)
      this.fields = { ...this.fields, ...fields }
      this.listOfFields.forEach((element) => {
        switch (element.type) {
          case 5:
            if ((element.default_value && element.default_value.isAPI)) {
              if (typeof this.fields[element.name] === 'string') {
                element.default_value.options = []
                element.default_value.options.push(this.fields[element.name])
              } else {
                element.default_value.options = this.fields[element.name]
              }
            }
            break
          case 6: element.date_value = this.$formatter.formatDate(this.fields[element.name], this.userDetails.dateformat, 'YYYY-MM-DD')
            break
          case 15:
            element.default_value.grid_data = this.fields[element.name].grid_data
            break
          case 12:
          case 18:
            element.default_value.grid_data.data = (this.fields && this.fields[element.name]) ? this.fields[element.name] : {}
            break
          case 13:
            setTimeout(() => {
              if (this.$refs.formReferences && this.$refs.formReferences.$refs[`signaturePad_${element._id}`] && this.$refs.formReferences.$refs[`signaturePad_${element._id}`].length) {
                this.$refs.formReferences.$refs[`signaturePad_${element._id}`][0].fromDataURL(this.fields[element.name])
              }
              //  to laod all the signatures inside panel
              if (this.$refs.formReferences && this.$refs.formReferences.$refs.panelFormReference) {
                this.$refs.formReferences.$refs.panelFormReference.forEach((x) => {
                  if ((x.$refs[`signaturePad_${element._id}`] && x.$refs[`signaturePad_${element._id}`].length)) {
                    x.$refs[`signaturePad_${element._id}`][0].fromDataURL(this.fields[element.name])
                  }
                })
              }
            }, 500)
            break
        }
      })
    },
    saveUpdateChecklistHandler (saveAndClose = false) {
      this.fields = this.$formatter.cloneVariable(this.fields)
      // 278 to 283 panelFields validation
      let isPanelFields = true
      if (this.$refs.formReferences && this.$refs.formReferences.$refs.panelFormReference) {
        this.$refs.formReferences.$refs.panelFormReference.forEach((x) => {
          isPanelFields = x.$refs.checklistForm.validate()
        })
      }
      if (this.$refs.formReferences && this.$refs.formReferences.$refs.checklistForm.validate() && isPanelFields) {
        this.constructValuesForParticularFields()
        const model = { title: '', form_id: this.formId, _id: this.checklistId }
        this.useAsTitleObj = this.listOfFields.find(x => x.default_value && x.default_value.use_as_title)
        if (this.useAsTitleObj && this.useAsTitleObj.name) model.title = this.fields[this.useAsTitleObj.name]
        model.data = JSON.stringify(this.fields)
        checklistApi[model._id ? 'put' : 'post'](model._id ? `checklists/${model._id}` : 'checklists', model, this.token).then(response => {
          if (response.data._id) this.uploadDocuments(response.data._id)
          const payload = { data: response.data, status: 'success', action: model._id ? 'update' : 'add', canClose: saveAndClose }
          if (this.useAsTitleObj && this.useAsTitleObj.name) payload.useAsTitleValue = this.fields[this.useAsTitleObj.name]
          this.$emit('update', payload)
        }).catch((error) => {
          this.$emit('update', { data: error, status: 'error' })
        }).finally(() => {
          if (saveAndClose) this.$emit('back')
        })
      }
    },
    // upload documents
    uploadDocuments (checklistId) {
      const result = this.listOfDocuments
      const getFiles = Object.keys(result)
      for (let i = 0; i < getFiles.length; i++) {
        const document = result[getFiles[i]]
        for (let j = 0; j < document.length; j++) {
          if (!document[j]._id) {
            const formData = new FormData()
            const getFile = document[j].getFiles
            for (let k = 0; k < getFile.length; k++) {
              formData.append(getFile[k].name, getFile[k])
            }
            checklistApi.post(`documents/upload?checklist_id=${checklistId}&property_id=${document[j].property_id}`, formData, this.token).then((response) => {
              if (response.data.length) this.getDocumentsHandler(checklistId)
            })
          }
        }
      }
    }
  },
  beforeDestroy () {
    this.$root.$off('saveCheckListHandler')
  }
}
</script>
