// eslint-disable-next-line camelcase
import { document_url } from '@/plugins/axios_settings'
export default {
  methods: {
    // eslint-disable-next-line camelcase
    mimeTypeOfAttachment ({ file_original_name, file_guid }) {
      let image = ''
      const ext = file_original_name.split('.').pop()
      const extension = ext ? ext.toString().toLowerCase() : ''
      switch (extension) {
        case 'png':
        case 'jpeg':
        case 'jpg':
        case 'gif':
          // eslint-disable-next-line camelcase
          image = { icon: 'mdi-file-image', color: null, url: `${document_url}/Documents/${file_guid}?t=${new Date().getTime()}` }
          break
        default:
          image = { icon: 'mdi-file-document', color: null, url: null }
          break
      }
      return image
    }
  }
}
